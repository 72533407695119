exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-blog-tsx": () => import("./../../../src/pages/blog.tsx" /* webpackChunkName: "component---src-pages-blog-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-talks-tsx": () => import("./../../../src/pages/talks.tsx" /* webpackChunkName: "component---src-pages-talks-tsx" */),
  "component---src-pages-thumbnail-tsx": () => import("./../../../src/pages/thumbnail.tsx" /* webpackChunkName: "component---src-pages-thumbnail-tsx" */),
  "component---src-templates-all-tags-index-tsx": () => import("./../../../src/templates/allTagsIndex.tsx" /* webpackChunkName: "component---src-templates-all-tags-index-tsx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2021-03-05-renovate-your-dependencies-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2021-03-05-renovate-your-dependencies/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2021-03-05-renovate-your-dependencies-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2021-05-02-gatsby-include-date-in-blog-post-slug-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2021-05-02-gatsby-include-date-in-blog-post-slug/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2021-05-02-gatsby-include-date-in-blog-post-slug-index-md" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2021-05-17-spring-mumlava-and-jizera-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2021-05-17-spring-mumlava-and-jizera/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2021-05-17-spring-mumlava-and-jizera-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-03-02-gatsby-post-code-formatting-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2022-03-02-gatsby-post-code-formatting/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-03-02-gatsby-post-code-formatting-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-03-22-self-documented-makefile-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2022-03-22-self-documented-makefile/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-03-22-self-documented-makefile-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-04-11-typescript-when-not-to-use-enums-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2022-04-11-typescript-when-not-to-use-enums/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-04-11-typescript-when-not-to-use-enums-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-05-28-webexpo-2022-my-preferred-program-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2022-05-28-webexpo-2022-my-preferred-program/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-05-28-webexpo-2022-my-preferred-program-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-08-12-switching-easily-php-versions-on-mac-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2022-08-12-switching-easily-php-versions-on-mac/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-08-12-switching-easily-php-versions-on-mac-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-09-13-creating-a-design-system-from-scratch-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2022-09-13-creating-a-design-system-from-scratch/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2022-09-13-creating-a-design-system-from-scratch-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2023-03-16-my-mac-setup-for-web-development-in-2023-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2023-03-16-my-mac-setup-for-web-development-in-2023/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2023-03-16-my-mac-setup-for-web-development-in-2023-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2023-04-11-dialog-element-dont-reinvent-the-wheel-with-modals-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2023-04-11-dialog-element-dont-reinvent-the-wheel-with-modals/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2023-04-11-dialog-element-dont-reinvent-the-wheel-with-modals-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-03-24-how-to-run-github-actions-lacally-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2024-03-24-how-to-run-github-actions-lacally/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-03-24-how-to-run-github-actions-lacally-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-03-24-how-to-transform-keys-in-object-and-other-usefull-code-collections-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2024-03-24-how-to-transform-keys-in-object-and-other-usefull-code-collections/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-03-24-how-to-transform-keys-in-object-and-other-usefull-code-collections-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-04-20-library-running-multiple-npm-scripts-is-maintained-again-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2024-04-20-library-running-multiple-npm-scripts-is-maintained-again/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-04-20-library-running-multiple-npm-scripts-is-maintained-again-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-08-28-deprecating-npm-packages-and-its-versions-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2024-08-28-deprecating-npm-packages-and-its-versions/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-08-28-deprecating-npm-packages-and-its-versions-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-09-13-how-to-cache-not-just-yarn-installs-on-github-actions-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/2024-09-13-how-to-cache-not-just-yarn-installs-on-github-actions/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-2024-09-13-how-to-cache-not-just-yarn-installs-on-github-actions-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-codes-index-mdx": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/codes/index.mdx" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-codes-index-mdx" */),
  "component---src-templates-blog-post-tsx-content-file-path-src-posts-uses-index-md": () => import("./../../../src/templates/blogPost.tsx?__contentFilePath=/Users/tomas.litera/Work/_literat/literat/src/posts/uses/index.md" /* webpackChunkName: "component---src-templates-blog-post-tsx-content-file-path-src-posts-uses-index-md" */),
  "component---src-templates-single-tag-index-tsx": () => import("./../../../src/templates/singleTagIndex.tsx" /* webpackChunkName: "component---src-templates-single-tag-index-tsx" */)
}

